import React, { useState } from "react";
import {
  Box,Input,
  Textarea, Stack,
  Button,FormControl,
  FormLabel,
  FormErrorMessage} from "@chakra-ui/react";
  import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
  import { toast, Slide } from 'react-toastify';
const ContactForm = () => {
  const { t } = useTranslation();
  
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [submit, setSubmit] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    /* eslint-disable no-useless-escape */
    const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return re.test(String(email).toLowerCase())
};
  const changeFullName = (event) => setFullName(event.target.value);
  const fullNameError = fullName === '';

  const changeEmail = (event) => {
      setEmail(event.target.value);
      const valid = event.target.value !== '' && validateEmail(event.target.value);
      setEmailError(!valid);
  };
  const changeSubject = (event) => setSubject(event.target.value);
  const subjectError = subject === '';

  const changeMessage = (event) => setMessage(event.target.value);
  const messageError = message === '';

  const sendMessage = () => {
      setSubmit(true);
      const valid = !fullNameError && !emailError && !subjectError && !messageError;
      
      if (valid) {
          const messageData = {
              name: fullName,
              email: email,
              subject: subject,
              message: message
          };
          setLoading(true);
          // eslint-disable-next-line no-undef
          fetch(process.env.GATSBY_API_URL, {
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "X-Requested-With": "XMLHttpRequest"
              },
              method: "POST",
              body: JSON.stringify(messageData)
          }).then((response) => {
              return response.json();
          }).then((data) => {
              if (data.status === 'OK') {
                  toast(t('Send Success'), {
                      position: "top-right",
                      autoClose: 4000,
                      transition: Slide,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });

                  setFullName('');
                  setEmail('');
                  setSubject('');
                  setMessage('');
                  setSubmit(false);
                  setLoading(false);
                  setEmailError(true);
              }

              if (data.status === 'FAIL'){
                  toast(t('Send Fail'), {
                      position: "top-right",
                      autoClose: 4000,
                      transition: Slide,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  });
              }
          })
      }
  }

  return (
    <Box display="inline-block" width={{base: "100%", sm: '100%', lg: "51.4%"}}
          mt={{base: 10, md: 10, lg: 0}}>
        <Box
            pl={{base: 0, sm: '0px', lg: '70px'}}
            backgroundColor="rgba(255, 255, 255, 1)"
            borderRadius="10px"
            boxShadow="0px 5px 30px rgb(5 5 5 / 8%)"
            padding={{ base: "40px", md: "60px" }}
            mb="0"

        >
            <Stack spacing={5}>
                <FormControl isRequired isInvalid={submit && fullNameError}>
                    <FormLabel><Trans>Full Name</Trans></FormLabel>
                    <Input
                        type="text"
                        value={fullName}
                        focusBorderColor="green.600"
                        onChange={(e) => changeFullName(e)} />
                    {submit && fullNameError && <FormErrorMessage> <Trans>Field is Required</Trans> </FormErrorMessage>}
                </FormControl>
                <FormControl isRequired isInvalid={submit && emailError}>
                    <FormLabel><Trans>Email</Trans></FormLabel>
                    <Input
                        type="email"
                        value={email}
                        focusBorderColor="green.600"
                        onChange={(e) => changeEmail(e)}
                    />
                    {submit && emailError && <FormErrorMessage> <Trans>Field is Required</Trans> </FormErrorMessage>}
                </FormControl>
                <FormControl isRequired isInvalid={submit && subjectError}>
                    <FormLabel><Trans>Subject</Trans></FormLabel>
                    <Input
                        type="text"
                        value={subject}
                        focusBorderColor="green.600"
                        onChange={(e) => changeSubject(e)} />
                    {submit && subjectError && <FormErrorMessage> <Trans>Field is Required</Trans> </FormErrorMessage>}
                </FormControl>
                <FormControl isRequired isInvalid={submit && messageError}>
                    <FormLabel><Trans>Message</Trans></FormLabel>
                    <Textarea
                        value={message}
                        focusBorderColor="green.600"
                        onChange={(e) => changeMessage(e)} />
                    {submit && messageError && <FormErrorMessage> <Trans>Field is Required</Trans> </FormErrorMessage>}
                </FormControl>
            </Stack>
            <Box width={'200px'} mt={5}>
                <Button
                    isLoading={loading}
                    backgroundColor="green.600"
                    _hover={{ backgroundColor: "teal.600" }}
                    color="white"
                    padding="20px 40px"
                    borderRadius="5px"
                    onClick={sendMessage}
                >
                    <Trans>Send Message</Trans>
                </Button>
            </Box>
        </Box>
    </Box>
  )
};

export default ContactForm;