import React from "react";
import { Box } from "@chakra-ui/react"
import { Map, Marker } from "pigeon-maps";
export default function SimpleMap(){
    const defaultProps = {
        center: {
            lat: 40.1762200,
            lng: 44.5129200
        },
        zoom: 16.8,
        color: "#005842"
    };
    return (
        <Box pos="relative" bottom="-6px">
          <Map height={350} defaultCenter={[defaultProps.center.lat, defaultProps.center.lng]} defaultZoom={defaultProps.zoom}>
            <Marker color={defaultProps.color} width={50} anchor={[defaultProps.center.lat, defaultProps.center.lng]} />
          </Map>
        </Box>
    );
}
