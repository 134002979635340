import React from "react";
import Main from "../../components/layouts/Main";
import Breadcrumb from "../../components/breadcrumb";
import bgContactUs from "../../images/contact-us.jpg";
import ContactForm from "../../components/contact/contactForm";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Box, Container,
    Flex, Heading,
    Text
  } from "@chakra-ui/react";
import { FaPhoneAlt, FaRegEnvelopeOpen } from "react-icons/fa";
import {graphql} from "gatsby";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import SimpleMap from "../../components/googleMap";
import Seo from "../../components/seo";
import SeoLogo from "../../images/seo-logo.png"
const Contact = () => {
    const { t } = useTranslation();
    const{ language } = useI18next();
    const url = language === 'hy' ? 'https://solidaccount.am/contact' : `https://solidaccount.am/${language}/contact`
    return (
        <Main>
            <Seo title={t('Contact')} url={url} logo={SeoLogo} />
            <Breadcrumb cover={bgContactUs} title={'Contact'}/>
            <Container maxW="7xl">
                <Box mt={16} mb={10}>
                    <Flex alignContent="center" alignItems="center" margin="0 auto" pos="relative"
                          flexDirection={{base: 'column', sm: "column", lg: 'row'}}>
                        <Box display="inline-block" width={{base: "100%", sm: '100%', lg: "48.6%"}}>
                            <Heading as="h5" color="green.600" fontSize="18px" mb="1.5rem">
                                <Trans>Contact</Trans>
                            </Heading>

                            <Flex gap={10} flexDirection={{base: 'column', sm: 'row'}}>
                                <Flex>
                                    <Box
                                        backgroundColor="green.600"
                                        padding="20px"
                                        borderRadius="5px"
                                        mr="15px"
                                    >
                                        <a href={`tel:010-54-40-99`}>
                                            <FaPhoneAlt color="white" fontSize="28px" />
                                        </a>
                                    </Box>
                                    <Box>
                                        <Heading as="h3" margin="10px 0" fontSize="18px" fontWeight="500"><Trans>Phone Call</Trans></Heading>
                                        <Text color="rgba(121, 130, 144, 1)">
                                            <a href={`tel:010-54-40-99`}>
                                                010-54-40-99
                                            </a>
                                        </Text>
                                    </Box>
                                </Flex>
                                <Flex>
                                    <Box
                                        backgroundColor="green.600"
                                        padding="20px"
                                        borderRadius="5px"
                                        mr="15px"
                                    >
                                        <a href={`mailto:info@solidaccount.am`}>
                                            <FaRegEnvelopeOpen color="white" fontSize="28px" />
                                        </a>
                                    </Box>
                                    <Box>
                                        <Heading as="h3" margin="10px 0" fontSize="18px" fontWeight="500"><Trans>Send Us A Message</Trans></Heading>
                                        <Text color="rgba(121, 130, 144, 1)">
                                            <a href={`mailto:info@solidaccount.am`}>
                                                info@solidaccount.am
                                            </a>
                                        </Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                        <ContactForm />
                    </Flex>
                </Box>
            </Container>
            <SimpleMap />
            <ToastContainer/>
        </Main>
    )
}

export default Contact
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;